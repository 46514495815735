import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import AppLayout from "../layouts/AppLayout.vue";
import AuthLayout from "../layouts/AuthLayout.vue";

import RouteViewComponent from "../layouts/RouterBypass.vue";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/:pathMatch(.*)*",
		redirect: { name: "projects" },
	},
	{
		name: "admin",
		path: "/",
		component: AppLayout,
		redirect: { name: "projects" },
		children: [
			{
				name: "dashboard",
				path: "dashboard",
				component: () => import("../pages/admin/dashboard/Dashboard.vue"),
			},
			{
				name: "settings",
				path: "settings",
				component: () => import("../pages/settings/Settings.vue"),
			},
			{
				name: "preferences",
				path: "preferences",
				component: () => import("../pages/preferences/Preferences.vue"),
			},
			{
				name: "users",
				path: "users",
				component: () => import("../pages/users/UsersPage.vue"),
			},
			{
				name: "projects",
				path: "projects",
				component: () => import("../pages/projects/ProjectsPage.vue")
			},
			{
				name: "project-execution",
				path: "project-execution/:id",
				component: () => import("../pages/projects/widgets/ProjectExecutions.vue"),
			},
			{
				name: "project-test-case",
				path: "project-test-case/:id",
				component: () => import("../pages/projects/widgets/ProjectTestCase.vue"),
			},
			{
				name: "edit-project-test-case",
				path: "edit-project-test-case/:id",
				component: () => import("../pages/projects/widgets/EditProjectTestCase.vue"),
			},
			{
				name: "create-test-editor",
				path: "create-test-editor/:id",
				component: () => import("../pages/projects/test-editor/CreateTestEditor.vue"),
			},
			{
				name: "contract-tests",
				path: "contract-tests",
				component: () => import("../pages/contract-tests/ContractTestsPage.vue"),
			},
			{
				name: "contract-test",
				path: "/contract-tests/:id",
				component: () => import("../pages/contract-tests/ContractTestPage.vue")
			},
			{
				name: "payments",
				path: "/payments",
				component: RouteViewComponent,
				children: [
					{
						name: "payment-methods",
						path: "payment-methods",
						component: () => import("../pages/payments/PaymentsPage.vue"),
					},
					{
						name: "billing",
						path: "billing",
						component: () => import("../pages/billing/BillingPage.vue"),
					},
					{
						name: "pricing-plans",
						path: "pricing-plans",
						component: () => import("../pages/pricing-plans/PricingPlans.vue"),
					},
				],
			},
			{
				name: "faq",
				path: "/faq",
				component: () => import("../pages/faq/FaqPage.vue"),
			},
		],
	},
	{
		path: "/auth",
		component: AuthLayout,
		children: [
			{
				name: "login",
				path: "login",
				component: () => import("../pages/auth/Login.vue"),
			},
			{
				name: "signup",
				path: "signup",
				component: () => import("../pages/auth/Signup.vue"),
			},
			{
				name: "recover-password",
				path: "recover-password",
				component: () => import("../pages/auth/RecoverPassword.vue"),
			},
			{
				name: "recover-password-email",
				path: "recover-password-email",
				component: () => import("../pages/auth/CheckTheEmail.vue"),
			},
			{
				path: "",
				redirect: { name: "login" },
			},
		],
	},
	{
		name: "404",
		path: "/404",
		component: () => import("../pages/404.vue"),
	},
];

const router = createRouter({
	history: createWebHistory(),
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		}
		// For some reason using documentation example doesn't scroll on page navigation.
		if (to.hash) {
			return { el: to.hash, behavior: "smooth" };
		} else {
			window.scrollTo(0, 0);
		}
	},
	routes,
	linkActiveClass: "active",
});

export default router;
