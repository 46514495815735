export interface INavigationRoute {
  name: string;
  displayName: string;
  meta: { icon: string };
  children?: INavigationRoute[];
}

export default {
	root: {
		name: "/",
		displayName: "navigationRoutes.home",
	},
	routes: [
		{
			name: "projects",
			displayName: "menu.projects",
			meta: {
				icon: "folder_shared",
			},
		},
		{
			name: "contract-tests",
			displayName: "menu.contract-testing",
			meta: {
				icon: "gavel",
			},
		},
		{
			name: "settings",
			displayName: "menu.settings",
			meta: {
				icon: "settings",
			},
		},
	] as INavigationRoute[],
};
