<script lang="ts" setup>
import axios from "axios";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useAuth } from "./composables/useAuth";
import { ElLoading } from "element-plus";
import { useColors } from "vuestic-ui";

const { setAuthContext, buildInfoByEnv, isLoginFinish } = useAuth();
const router = useRouter();
const { applyPreset } = useColors();

const loading = ref(false);

onMounted(async () => {
	(window as any).process = {
		env: {},
		argv: [],
	};
	applyPreset("dark");
	await authenticate();
});

const authenticate = async () => {
	loading.value = true;
	isLoginFinish.value = false;

	const loadingInstance = ElLoading.service({ fullscreen: true, text: "Authenticating" });

	const { redirectAuth, authBaseUrl } = buildInfoByEnv();

	const urlParams = new URLSearchParams(window.location.href.split("?")[1]);

	const sessionCookie = getCookie("__rds_sesh");

	let sessionToken = "";

	if (sessionCookie) {
		try {
			sessionToken = JSON.parse(sessionCookie)["_at"];
		} catch (e) {
			console.error("error", e);
		}
	}

	if (urlParams.get("token")) {
		localStorage.setItem("tToken", urlParams.get("token") || "");
	}

	const token =
    urlParams.get("token") || sessionToken || localStorage.getItem("tToken");

	const localStorageUser = localStorage.getItem("user");
	const localStorageToken = localStorage.getItem("token");
	const localStorageRoleData = localStorage.getItem("roleData");

	const user = localStorageUser
		? JSON.parse(localStorageUser)
		: localStorageUser;
	const userToken = localStorageToken || "";
	const roleData = localStorageRoleData
		? JSON.parse(localStorageRoleData)
		: localStorageRoleData;

	const currentTime = new Date().getTime();
	const isExpired = user ? currentTime > user.dateExpiration : true;

	if (user && !isExpired && roleData) {
		setAuthContext(user, userToken, roleData || []);
		loadingInstance.close();
		isLoginFinish.value = true;
	} else if (token) {
		try {
			let authorizationToken = "";

			if (!sessionToken || localStorage.getItem("tToken")) {
				const tokenReq = localStorage.getItem("tToken") || token;
				const responseToken = await axios.post(
					`${authBaseUrl}/iam-auth/public/api/v1/auth/tokens/authorization`,
					{
						transitionalToken: tokenReq.split("#")[0],
					},
				);

				localStorage.removeItem("tToken");

				authorizationToken = responseToken.data.data.authorizationToken;
			} else {
				authorizationToken = sessionToken;
			}

			const responseUser = await axios.get(`${authBaseUrl}/iam/api/v1/users`, {
				headers: { Authorization: authorizationToken },
			});

			const date = new Date();
			date.setHours(date.getHours() + 6);

			const userData = {
				...responseUser.data.data,
				dateExpiration: date.getTime(),
			};

			const responseRole = await axios.get(
				`${authBaseUrl}/iam-roles/api/v1/login-info/roles`,
				{
					headers: { Authorization: authorizationToken },
				},
			);

			let roleData: string[] = [];

			responseRole.data.data.forEach((role: any) => {
				roleData = roleData.concat(role.roles);
			});

			setAuthContext(userData, authorizationToken, roleData);

			localStorage.setItem("roleData", JSON.stringify(roleData));
			localStorage.setItem("user", JSON.stringify(userData));
			localStorage.setItem("token", authorizationToken);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			router.replace({ token: null } as any);
			loadingInstance.close();
			isLoginFinish.value = true;
		} catch (e) {
			loadingInstance.close();
			console.error(e);
			if (String(e).includes("401")) {
				redirectToLogin(redirectAuth);
			}
		}
	} else {
		loadingInstance.close();
		redirectToLogin(redirectAuth);
	}
};

const redirectToLogin = (redirectAuth: string) => {
	const loadingInstance = ElLoading.service({ fullscreen: true, text: "Redirecting to login ..." });

	const redirectUrl = `${redirectAuth}/index.html?origin=bank&urlRedirect=${window.location.origin}?token=`;
	setTimeout(() => {
		(window as any).location = redirectUrl;
		loadingInstance.close();
	}, 3000);
};

const getCookie = (name: string) => {
	const nameEQ = name + "=";
	const ca = document.cookie.split(";");
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === " ") c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
	}
	return null;
};
</script>

<template>
  <div class="dark">
    <RouterView />
  </div>
</template>

<style lang="scss">
@import "scss/main.scss";

#app {
  font-family: "Inter", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  min-width: 20rem;
}
</style>
