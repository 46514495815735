<template>
  <div class="flex gap-2">
    <VaIconMenuCollapsed
      class="cursor-pointer"
      :class="{ 'x-flip': !isSidebarMinimized }"
      :color="collapseIconColor"
      @click="isSidebarMinimized = !isSidebarMinimized"
    />

    <nav class="flex items-center">
      <VaBreadcrumbs>
        <VaBreadcrumbsItem label="Projects" :to="{ name: 'projects' }" />
		<VaBreadcrumbsItem label="Test Cases" @click="routerTo(projectTestCaseRoute)" class="cursor-pointer" v-if="isRouter(['edit-project-test-case', 'create-test-editor'])"/>
		<VaBreadcrumbsItem :label="projectTest.name" @click="routerTo(projectTest.route)" class="cursor-pointer" v-if="isRouter(['create-test-editor'])"/>
      </VaBreadcrumbs>
    </nav>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useColors } from "vuestic-ui";
import { useNavigation } from "../../composables/useNavigation";
import { useGlobalStore } from "../../stores/global-store";
import VaIconMenuCollapsed from "../icons/VaIconMenuCollapsed.vue";

const { isSidebarMinimized } = storeToRefs(useGlobalStore());

const router = useRouter();
const { projectTestCaseRoute, projectTest } = useNavigation();

const routerTo = (path: string) => {
	router.replace({ path });
};

const isRouter = (route: string[]) => {
	return route.some(item => item == router.currentRoute.value.name);
};

const { getColor } = useColors();

const collapseIconColor = computed(() => getColor("secondary"));
</script>

<style lang="scss" scoped>
.x-flip {
  transform: scaleX(-100%);
}

.cursor-pointer {
	cursor: pointer;
}
</style>
