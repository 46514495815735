export default [
	{
		name: "angle_down",
		to: "fa4-angle-down",
	},
	{
		name: "angle_up",
		to: "fa4-angle-up",
	},
	{
		name: "bell",
		to: "fa4-bell",
	},
	{
		name: "bell_slash",
		to: "fa4-bell-slash",
	},
	{
		name: "cogs",
		to: "fa4-cogs",
	},
	{
		name: "envelope",
		to: "fa4-envelope",
	},
	{
		name: "eye",
		to: "fa4-eye",
	},
	{
		name: "gear",
		to: "fa4-gear",
	},
	{
		name: "map",
		to: "fa4-map",
	},
	{
		name: "map_marker",
		to: "fa4-map-marker",
	},
	{
		name: "music",
		to: "fa4-music",
	},
	{
		name: "print",
		to: "fa4-print",
	},
	{
		name: "refresh",
		to: "fa4-refresh",
	},
	{
		name: "search",
		to: "fa4-search",
	},
	{
		name: "mars",
		to: "fa4-mars",
	},
	{
		name: "venus",
		to: "fa4-venus",
	},
	{
		name: "volume_off",
		to: "fa4-volume-off",
	},
	{
		name: "volume_up",
		to: "fa4-volume-up",
	},
	{
		name: "github",
		to: "fa4-github",
	},
	{
		name: "md_close",
		to: "ion-md-close",
	},
	{
		name: "images",
		to: "ion-md-images",
	},
	{
		name: "list",
		to: "ion-md-list",
	},
	{
		name: "musical_notes",
		to: "ion-md-musical-notes",
	},
	{
		name: "star_outline",
		to: "ion-md-star-outline",
	},
	{
		name: "grid",
		to: "ion-md-grid",
	},
	{
		name: "help",
		to: "ion-md-help",
	},
	{
		name: "key",
		to: "ion-md-key",
	},
];
