import { ref } from "vue";

const projectTestCaseRoute = ref("");
const projectTest = ref({
	route: "",
	name: ""
});

export function useNavigation() {
	return {
		projectTestCaseRoute,
		projectTest,
		setProjectTestCaseRoute: (id: string) => projectTestCaseRoute.value = `/project-test-case/${id}`,
		setProjectTest: (id: string, name: string) => {
			projectTest.value = {
				route: `/edit-project-test-case/${id}`,
				name
			};
		}
	};
}
