import { createGtm } from "@gtm-support/vue-gtm";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "element-plus/theme-chalk/dark/css-vars.css";
import "vanilla-jsoneditor/themes/jse-theme-dark.css";
import { createApp } from "vue";
import { createVuestic } from "vuestic-ui";
import i18n from "./i18n";
import App from "./App.vue";
import router from "./router";
import vuesticGlobalConfig from "./services/vuestic-ui/global-config";
import stores from "./stores";

const app = createApp(App);

app.use(stores);
app.use(router);
app.use(i18n);
app.use(createVuestic({ config: vuesticGlobalConfig }));
app.use(ElementPlus);

if (import.meta.env.VITE_APP_GTM_ENABLED) {
	app.use(
		createGtm({
			id: import.meta.env.VITE_APP_GTM_KEY,
			debug: false,
			vueRouter: router,
		}),
	);
}

app.mount("#app");
